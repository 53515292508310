











































































































































import { Cliente, Porto, Usuario } from "@/core/models/geral";
import { Embarcacao, RegistroOperacional, RelatorioMedicao } from "@/core/models/operacional";
import { LocalOperacao } from "@/core/models/operacional/LocalOperacao";
import { PageBase } from "@/core/models/shared";
import { EnumTipoUsuario } from "@/core/models/shared/Enumerados";
import { ClienteService, PortoService, UsuarioService } from "@/core/services/geral";
import { EmbarcacaoService, RelatorioMedicaoService } from "@/core/services/operacional";
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from "@/core/services/shared/AlertService";
import { Component, Prop, Watch} from "vue-property-decorator";

@Component
export default class ListaRegistroOperacional extends PageBase {
    search: any = '';
    loading: boolean = false;
    dialogCadastro: boolean = false;
    dialogEnviarROEmail: boolean = false;
    totalLista: number = 0;
    lista: any[] = [];
    sheet: boolean = false;
    valid = true;
    overlay: boolean = false;

    tipoUsuario = EnumTipoUsuario;

    options: any = {
        itemsPerPage: 15
    };
    $refs!:{
        form: HTMLFormElement
    }
    headers: any[] = [
        { text: '',value:'actions' ,sortable: false },
        { text: '#', value: 'numero', type:'number', sortable: false },
        { text: '# RO', value: 'registroOperacional.numero', type:'number' },
        { text: 'Data Referência', value: 'dataReferencia', type:'date' },
        { text: 'Embarcação', value: 'registroOperacional.embarcacao.nome' },
        { text: 'Cliente', value: 'registroOperacional.proposta.cliente.nome' },
        { text: 'Porto', value: 'registroOperacional.porto.nome' },
        { text: 'Local Operação', value: 'registroOperacional.atracacao.localOperacao.nome' },
        { text: 'Aprovador', value: 'aprovador.complementar.nome', sortable: false }
    ];
    filtro: any = {
        numero: null,
        numeroRo: null,
        dataReferencia: null,
        clienteId: null,
        usuarioId: null,
        embarcacaoId: null,
        portoId: null,
        localOperacaoId: null
    };

    item = new RelatorioMedicao();
    service = new RelatorioMedicaoService();

    onSearchCliente: any = null;
    isClienteLoading: boolean = false;
    clienteService = new ClienteService();
    clientes: Cliente[] = [];

    onSearchUsuario: any = null;
    isUsuarioLoading: boolean = false;
    usuarioService = new UsuarioService();
    usuarios: Usuario[] = [];

    onSearchEmbarcacao: any = null;
    isEmbarcacaoLoading: boolean = false;
    embarcacaoService = new EmbarcacaoService();
    embarcacoes: Embarcacao[] = [];

    isPortoLoading: boolean = false;
    portoService = new PortoService();
    portos: Porto[] = [];
    localOperacao: LocalOperacao[] = [];

    gerarDialog: boolean = false;
    dataGerar: string = "";

    @Watch('options', { deep: true })
    Atualizar(isFiltro: boolean = false){

        if(isFiltro){
            this.options.page = 1;
        }
        this.sheet = false;
        const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
        this.loading = true;
        this.service.ListarComFiltro(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, this.filtro, "RegistroOperacional.Porto, RegistroOperacional.Embarcacao, RegistroOperacional.Atracacao.LocalOperacao, RegistroOperacional.Proposta.Cliente, Aprovador").then(
            res => {
                this.lista = res.data.items;
                this.totalLista = res.data.count;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.loading = false));
    }

    @Watch('onSearchCliente')
    SearchCliente (val: string) {
        if (this.isClienteLoading) return;
        if(this.filtro.clienteId) return;
        if (!val) return;
        this.isClienteLoading = true
        this.clienteService.AutoComplete(val)
        .then(
            res => {
                this.clientes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        .finally(() => (this.isClienteLoading = false));
    }

    @Watch('onSearchUsuario')
    SearchUsuario (val: string) {
        if (this.isUsuarioLoading) return;
        if(this.filtro.usuarioId) return;
        if (!val) return;
        this.isUsuarioLoading = true
        this.usuarioService.AutoComplete(val)
        .then(
            res => {
                this.usuarios = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        .finally(() => (this.isUsuarioLoading = false));
    }

    @Watch('onSearchEmbarcacao')
    SearchEmbarcacao (val: string) {
        if (this.isEmbarcacaoLoading) return;
        if(this.filtro.embarcacaoId) return;
        if (!val) return;
        this.isEmbarcacaoLoading = true
        this.embarcacaoService.AutoComplete(val).then(
            res => {
                this.embarcacoes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        .finally(() => (this.isEmbarcacaoLoading = false));
    }

    @Watch('filtro.portoId')
    WatchPortoFiltro(){
        this.localOperacao = [];
        if(this.filtro.portoId > 0){
            this.localOperacao = this.portos.find(x => x.id == this.filtro.portoId)!.locaisOperacao;
        }        
    }

    mounted() {
        this.Carregar()
    }

    Carregar(){
        this.portoService.ListarTudo("LocaisOperacao").then(
            res => {
                this.portos = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Gerar(gerar: boolean){
        if(gerar){
            if(this.dataGerar.length > 0){
                this.service.Gerar(this.dataGerar).then(
                    res => {
                        AlertSimpleRes("Aviso!", res);
                    },
                    err => AlertSimpleErr("Aviso!", err)
                ).finally(() => {
                    this.gerarDialog = false;
                    this.overlay = false;
                    this.Atualizar();
                })
            }
            else{
                AlertSimple("Aviso", "Selecione uma data", "warning");
            }
        }
        else{
            this.gerarDialog = true;
        }
    }

    AbrirDialogCadastro(item: RelatorioMedicao){
        this.service.ObterPorId(item.id, "RegistroOperacional.Proposta.Cliente, Itens.RegistroOperacionalEquipamento.Equipamento").then(
            res=>{
                this.item = new RelatorioMedicao(res.data);
                this.dialogCadastro = true;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    AbrirJanela(id: number, rota: string) {
        let routeLink = this.$router.resolve({ name: rota, params: { id: id.toString() } });
        window.open(routeLink.href, '_blank');
    }

    Excluir(item: RegistroOperacional){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                await context.service.Excluir(item.id).then(
                    res => {
                        if(res.status == 200){
                            return resolve(res.data);
                        }
                    },
                    err => {
                        AlertSimpleErr("Aviso", err);
                    }
                ).finally(() => {
                    context.Atualizar();
                })
            });
        }
        AlertExcludeQuestion(excluir);
    }

}
